import React from "react"
import Project from "./Project"
import * as archiveStyles from "./Project.scss"

const Projects = ({ projects, title }) => {
  return (
    <>
      <section className={"projects"}>
        <div className={"projects-wrapper"}>
          {projects.map(project => {
            return <Project key={project.id} {...project} />
          })}
        </div>
        <div className={"archive-description"}>
          <h1 className={"archive-title"}>{title}</h1>

          <p>
            I'm baby ugh venmo chambray distillery af shabby chic four loko,
            food truck vegan plaid quinoa. Fam edison bulb humblebrag, migas
            artisan bicycle rights gochujang. Leggings lyft kickstarter, mlkshk
            drinking vinegar artisan af kitsch messenger bag slow-carb photo
            booth. Banh mi hashtag yuccie etsy poke. Marfa copper mug tousled
            pitchfork banh mi affogato put a bird on it semiotics four loko
            chartreuse live-edge, portland bicycle rights.
          </p>
          <p>
            Helvetica flannel salvia, everyday carry narwhal kickstarter
            heirloom chartreuse hella sriracha bushwick. Tacos vaporware man
            braid pug taxidermy flexitarian farm-to-table craft beer gentrify
            locavore adaptogen single-origin coffee edison bulb. Paleo gochujang
            palo santo taiyaki, blog narwhal deep v humblebrag plaid taxidermy
            vegan austin pinterest. Snackwave mixtape meditation, knausgaard
            vaporware stumptown listicle narwhal godard williamsburg gentrify
            neutra kickstarter typewriter post-ironic.
          </p>
          <p>
            Helvetica flannel salvia, everyday carry narwhal kickstarter
            heirloom chartreuse hella sriracha bushwick. Tacos vaporware man
            braid pug taxidermy flexitarian farm-to-table craft beer gentrify
            locavore adaptogen single-origin coffee edison bulb. Paleo gochujang
            palo santo taiyaki, blog narwhal deep v humblebrag plaid taxidermy
            vegan austin pinterest. Snackwave mixtape meditation, knausgaard
            vaporware stumptown listicle narwhal godard williamsburg gentrify
            neutra kickstarter typewriter post-ironic.
          </p>
        </div>
      </section>
    </>
  )
}

export default Projects
