import React from "react"
import Image from "gatsby-image"
import { Link } from "gatsby"
import * as archiveStyles from "./Project.scss"

const Project = ({ frontmatter }) => {
  const { image, slug } = frontmatter

  return (
    <>
      <div className={"archive-project"}>
        <Link to={`/projects/${slug}`} className={"link"}>
          <Image
            fluid={image.childImageSharp.fluid}
            className={"projects-teaser-img"}
          />
        </Link>
      </div>
    </>
  )
}

export default Project
