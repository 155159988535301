import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Projects from "../components/projects"
import SEO from "../components/seo"
import Container from "react-bootstrap/Container"

const ProjectsPage = ({ data }) => {
  const {
    allMdx: { nodes: projects },
  } = data

  return (
    <>
      <SEO title="Projects" />
      <Layout>
        <Projects projects={projects} title={"Our Project"} />
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: ASC }) {
      nodes {
        excerpt
        frontmatter {
          title
          author
          date(formatString: "MMMM Do,YYYY")
          slug
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        id
      }
    }
  }
`

export default ProjectsPage
